.cart_cont { 
	position: relative;
  }
  
  .title_cart {
	margin: 30px;
  }
  
  .clear_btn {
	background-color: #339933;
	color: white;
	margin: 30px 0 0px 300px;
	padding: 10px;
	width: 200px;
	border-radius: 10px;   
	cursor: pointer;
	text-align: center;
	font-size: 24px;
	
   }
  
  .empty {
	margin-left: 30px;
  }
  
  .order { 
    border: 2px solid rgb(0, 0, 0);
    width: 824px;
    padding: 15px;
    margin: 80px 0 0 30px;
    display: flex;
    justify-content: space-between;
	border-radius: 25px;
	}
  
	.order h2 {
	 padding: 20px 0 0 25px;
	  font-size: 28px;
	}

	.cart_cont h3 {
		padding: 20px 0 0 25px;
		 font-size: 50px;
		 color: rgb(255, 255, 255);
		 text-shadow: -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black, 2px 2px 0 black;
		
		
	   }
  
	.order p {
	  padding: 50px 0 0 25px;
	  font-size: 30px;
	  word-spacing: 280px;
	}
	  
	.pfon_ord {
	  display: flex;
	  flex-direction: column;
	}


	  .order p {
    padding: 50px 0 0 25px;
    font-size: 24px;
    word-spacing:10px;
  }
	  
	
	
	.order input{    
	border: 1px solid black;
	border-radius: 20px; 
	width: 446px; 
	height: 73px;
	margin: 70px 25px 25px 20px;
	padding-left: 20px;
	font-size: 25px;
	}
  
	.order button {
	  border: 1px solid black;
	  border-radius: 20px; 
	  width: 446px; 
	  height: 73px;
	  background-color: #339933;
	  color: white;
	  margin-left: 25px;      
	  font-size: 28px;
	  cursor: pointer;
	  
	}
  

  