.card_item {
	position: relative;
}

.card_item:hover .add_btn {
    visibility: visible;
  }

.card {
	
	/* border: 1px solid black; */
    padding: 10px;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center
}

.card img {
	border-radius: 30%;   
    height: 300px;
    width: 300px;
    object-fit: contain;
	border: 5px solid;
}

.price_info {
    display: flex;
    justify-content: center;
    align-items: center;   
    gap: 20px;
  }

  .price_info p:nth-child(1) {
    text-align: center;
    margin: 4px 0;      
    color: black;
    font-weight: 600;
    font-size: 30px;
    padding-left: 4px;
  }

  .price_info p:nth-child(2) {    
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    color: red;
  }

  
.card p:last-child{
	text-align: center;
	margin: 4px 0;
}

.card p:nth-child(2) {
	color: #000;;
	font-weight: 600;
}



.add_btn {
	visibility: hidden;
    cursor: pointer;
    background-color: rgb(247, 244, 159);
    color: rgb(1, 107, 1);
    border: 2px solid green;
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
    position: absolute;
    top: 40%;
    left: 35%;
    font-weight: 600;
}

.card_item:hover .add_btn {
	visibility: visible;
}

.discounted-price {
	text-decoration: line-through; /* Перечеркивание текста */
    color: #ffffff; /* Цвет для перечеркнутой цены (по вашему выбору) */
    font-size: 20px;
    font-weight: 500;
    padding-left: 40px;
	letter-spacing: 0.9px;
  }

  .title {
	text-align: center;
	font-weight: 800;
  }

 
  
   
 