.product_item {
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
    width: 900px;
    margin: 40px auto;
  }
  
  .product_item img {
    height: 300px;
    width: 300px;
    object-fit: contain;
	border-radius: 30%;
	border: 5px solid;
  }
  
  .product_item > div p:first-child {
    font-weight: 700;
    font-size: 24px;
    margin: 15px 0;
  }
  
  .product_item > div p:nth-child(3) {
    color: rgb(7, 7, 7);
    font-weight: 700;
    margin: 10px 0;
    font-size: 20px;
  }
  
  .product_item > div p:last-child {
    background-color: green;
    color: white;
    border-radius: 10px;
    padding: 15px;
    width: fit-content;
    margin: 15px 0;
  }

  .add_btn {
    cursor: pointer;
    background-color: rgb(247, 244, 159);
    color: green;
    border: 2px solid green;
    border-radius: 10px;
    padding: 10px;
    width: fit-content;
  }